
const termsText= {
    0:   {
       en:'Effective Date: 01.08.2023',
       ro:'Data efectivă: 01.08.2023'
    },
    1:   {
       en:`Please read these Terms and Conditions carefully before using the EdNeos Mobile App or Web Platform. By accessing or using the App, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these Terms and Conditions, you must not use the App/the Web Platform.`,
       ro:`Vă rugăm să citiți cu atenție Termenii și Condițiile înainte de a utiliza aplicația mobilă sau platforma web EdNeos. Prin accesarea sau utilizarea aplicației, vă exprimați explicit acordul să respectați acești Termeni și Condiții. Dacă nu sunteți de acord cu oricare parte a acestora, vă rugăm să nu utilizați aplicația/platforma web.`
    },
    2:   {
       en:`1. License to Use the App:`,
       ro:`1. Licența pentru utilizarea aplicației:`
    },
    3:   {
       en:`The App is offered in different packages - Free, Pro, and Premium. Each package grants you a license to use the App for educational and commercial purposes, subject to these Terms and Conditions. The Free package is provided at no cost, while the Pro and Premium packages are offered in exchange for a fee.`,
       ro:`Aplicația este oferită în diferite pachete - Free, Pro și Premium. Fiecare pachet vă oferă o licență pentru a utiliza aplicația în scopuri educaționale și comerciale, în conformitate cu acești Termeni și Condiții. Pachetul Free este furnizat gratuit, în timp ce pachetele Pro și Premium sunt oferite contra unei taxe.`
    },
    4:   {
       en:`2. Intellectual Property:`,
       ro:`2. Proprietate intelectuală:`
    },
    5:   {
       en:`All intellectual property rights in the App and its content, including but not limited to copyrights, trademarks, and trade secrets, are owned by or licensed to us. You agree not to copy, modify, distribute, sell, or exploit any part of the App without our prior written consent.`,
       ro:`Toate drepturile de proprietate intelectuală asupra aplicației și conținutului său, inclusiv, dar fără a se limita la drepturile de autor, mărci comerciale și secrete comerciale, sunt deținute și licențiate de către EdNeos. Sunteți de acord să nu copiați, modificați, distribuiți, vindeți sau exploatați nicio parte a aplicației fără acordul nostru scris în prealabil.`
    },
    6:   {
       en:`3. User Content:`,
       ro:`3. Conținutul utilizatorului:`
    },
    7:   {
       en:`By using the App, you may submit or upload certain content, such as flashcard sets, images, or other materials ("User Content"). You retain all ownership rights in your User Content. By submitting User Content, you grant us a worldwide, royalty-free, non-exclusive license to use and modify your User Content for the purpose of operating the App and providing the service.`,
       ro:`Prin utilizarea aplicației, puteți trimite sau încărca anumite tipuri de conținut, precum seturi de cărți cu întrebări și răspunsuri, imagini sau alte materiale ("Conținutul utilizatorului"). D-voastră aveți toate drepturile de proprietate asupra conținutului utilizatorului. Prin trimiterea Conținutului de Utilizator, ne acordați o licență generală, fără drepturi de autor, non-exclusivă de a utiliza și modifica Conținutul Utilizatorului în scopul de a opera aplicația și furniza serviciul.`
    },
    8:   {
       en:`4. User Conduct:`,
       ro:`4. Conduită a utilizatorului:`
    },
    9:   {
       en:`You agree to use the App responsibly and in compliance with all applicable laws and regulations. You will not:`,
       ro:`Sunteți de acord să utilizați aplicația responsabil și în conformitate cu toate legile și reglementările aplicabile. Nu veți:`
    },
    10:   {
       en:`- Use the App for any unlawful or fraudulent purpose.`,
       ro:`- Utiliza aplicația în scopuri ilegale sau frauduloase.`
    },
    11:   {
       en:`- Transmit any viruses, malware, or harmful code through the App.`,
       ro:`- Transmite viruși, programe malware sau coduri dăunătoare prin intermediul aplicației.`
    },
    12:   {
       en:`- Impersonate any person or entity or misrepresent your affiliation with any person or entity.`,
       ro:`- Impersona o persoană sau entitate sau să vă reprezentați în mod fals afilierea cu o persoană sau entitate.`
    },
    13:   {
       en:`- Interfere with the proper functioning of the App or circumvent any security measures.`,
       ro:`- Împiedicați funcționarea corectă a aplicației sau circumveniți măsurile de securitate.`
    },
    14:   {
       en:`5. Privacy Policy:`,
       ro:`5. Politica de confidențialitate:`
    },
    15:   {
       en:`Your privacy is important to us. Please review our Privacy Policy [provide link] to understand how we collect, use, and disclose information through the App.`,
       ro:`Confidențialitatea dvs. este importantă pentru noi. Vă rugăm să consultați politica noastră de confidențialitate pentru a înțelege modul în care colectăm, utilizăm și dezvăluim informații prin intermediul aplicației.`
    },
    16:   {
       en:`6. Disclaimer of Warranties:`,
       ro:`6. Exonerare de garanții:`
    },
    17:   {
       en:`The App and its content are provided on an "as is" basis without warranties of any kind, whether express or implied. We do not guarantee the accuracy, completeness, or reliability of the App or its content. Our technical team works on a daily basis to ensure the best accuracy, completeness, and reliability for all users, and we encourage you to notify us of any issues you may experience while using EDNEOS's app or web platform at hello@edneos.com.You agree that your use of the App is at your own discretion and risk.`,
       ro:`Aplicația și conținutul său sunt furnizate "așa cum sunt", fără garanții de nicio natură, fie exprese, fie implicite. Nu garantăm exactitatea, completitudinea sau fiabilitatea aplicației sau a conținutului său. Echipa noastră tehnică lucrează zilnic pentru a asigura cea mai bună precizie, completitudine și fiabilitate pentru toți utilizatorii, și vă încurajăm să ne anunțați orice probleme pe care le-ați putea întâmpina în timp ce utilizați aplicația sau platforma web EDNEOS la adresa hello@edneos.com. Sunteți de acord însă că utilizarea aplicației este la propria dumneavoastră discreție și risc.`
    },
    18:   {
       en:`7. Limitation of Liability:`,
       ro:`7. Limitarea răspunderii:`
    },
    19:   {
       en:`To the extent permitted by law, we shall not be liable for any indirect, consequential, incidental, special, or punitive damages arising out of or in connection with the use of the App or its content.`,
       ro:`În măsura permisă de lege, nu vom fi responsabili pentru niciun prejudiciu indirect, consecvent, incident, special sau punitiv rezultat din utilizarea aplicației sau a conținutului său.`
    },
    20:   {
       en:`8. Package Upgrades and Payments:`,
       ro:`8. Upgrade-uri și plăți pentru pachete:`
    },
    21:   {
       en:`Upgrading to the Pro or Premium package requires payment of the specified fees. Payments are non-refundable, and you are responsible for any applicable taxes. We may change the pricing or features of packages at any time, but such changes will not affect existing paid subscriptions.`,
       ro:`Pentru a beneficia de pachetele Pro sau Premium, trebuie să plătiți taxele specificate. Plățile nu sunt rambursabile, iar dumneavoastră sunteți responsabil pentru orice taxe aplicabile. Ne rezervăm dreptul de a schimba prețurile sau caracteristicile pachetelor în orice moment, dar aceste modificări nu vor afecta abonamentele plătite existente.`
    },
    22:   {
       en:`9. Changes to the App and Terms:`,
       ro:`9. Modificări ale aplicației și termenilor:`
    },
    23:   {
       en:`We reserve the right to modify, suspend, or discontinue the App or any part of it at any time without notice. We may also revise these Terms and Conditions from time to time. By continuing to use the App after any changes to the Terms and Conditions, you accept and agree to the revised terms. All subscriptions already paid will be available until their due date.`,
       ro:`EdNeos își rezervă dreptul de a modifica, suspenda sau întrerupe aplicația sau orice parte a ei în orice moment și fără notificare prealabilă. De asemenea, la anumite intervale de timp, putem revizui acești Termeni și Condiții. Prin continuarea utilizării aplicației după orice modificări ale Termenilor și Condițiilor, acceptați și vă încadrați în termenii revizuiți. Toate abonamentele deja plătite vor fi disponibile până la data lor de scadență.`
    },
    24:   {
       en:`10. Termination:`,
       ro:`10. Încheiere:`
    },
    25:   {
       en:`We reserve the right to modify, suspend, or terminate your access to the App at our discretion, for any reason and without prior notice. Our primary aim is to provide a positive user experience for everyone. In case of any concerns or issues, we will strive to communicate with you in a fair and transparent manner. If access is terminated, we kindly ask that you cease all use of the App and remove it from your mobile device.`,
       ro:`Înțelegem importanța unei experiențe pozitive pentru toți utilizatorii. Ca urmare, ne rezervăm dreptul de a modifica, suspenda sau încheia accesul dvs. la aplicație, cu bună credință și în conformitate cu dispozițiile legale, fără notificare prealabilă. În cazul unor îngrijorări sau probleme, vom încerca să comunicăm cu dumneavoastră într-un mod corect și transparent. În cazul încheierii accesului, vă rugăm să încetați utilizarea aplicației și să o ștergeți de pe dispozitivul mobil.`
    },
    26:   {
       en:`11. Governing Law:`,
       ro:`11. Legea aplicabilă:`
    },
    27:   {
       en:`These Terms and Conditions shall be governed by and construed in accordance with the laws of `,
       ro:`Acești Termeni și Condiții vor fi guvernați și interpretați în conformitate cu legile din `
    },
    28:   {
       en:`Romania`,
       ro:`România`
    },
    29:   {
       en:`, without regard to its conflict of laws principles.`,
       ro:`, fără a ține cont de principiile de confluență a legilor.`
    },
    30:   {
       en:`If you have any questions or concerns about these Terms and Conditions, please contact us at `,
       ro:`Dacă aveți întrebări sau preocupări cu privire la acești Termeni și Condiții, vă rugăm să ne contactați la `
    },
    31:   {
       en:`hello@edneos.com`,
       ro:`hello@edneos.com`
    },
    32:   {
       en:`By using the EdNeos Mobile App and/or Web Platform, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.`,
       ro:`Prin utilizarea aplicației mobile EdNeos și/sau platformei web, recunoașteți că ați citit, înțeles și acceptat acești Termeni și Condiții.`
    }
   };
   
   export default termsText;