import classes from './Privacy.module.css';
import Background from '../../COMPONENTS/UI/Background/Background';
import Footer from '../../COMPONENTS/UI/Footer/Footer';
import useGetTranslatedText from '../../HOOKS/useGetTranslatedText'; 

function Privacy() {
  const pageText = useGetTranslatedText('privacyPage_TextTranslations.js');

  return (
    <Background>        
        <div className={classes.container}>
            <h3><b>{pageText[0]}</b></h3>
            <p>&nbsp;</p>
            <p className={classes.indent}>{pageText[1]}</p>
            <p>&nbsp;</p>
            {/* 1 */}
            <p><b>{pageText[2]}</b></p>
            <p className={classes.indent}>{pageText[3]}</p>
            <p>&nbsp;</p>
            {/* 2 */}
            <p><b>{pageText[4]}</b></p>
            <p className={classes.indent}>{pageText[5]}</p>
            <p>&nbsp;</p>
            {/* 3 */}
            <p><b>{pageText[6]}</b></p>
            <p className={classes.indent}>{pageText[7]}</p>
            <p>&nbsp;</p>
            {/* 4 */}
            <p><b>{pageText[8]}</b></p>
            <p className={classes.indent}>{pageText[9]}</p>
            <p>&nbsp;</p>
            {/* 5 */}
            <p><b>{pageText[10]}</b></p>
            <p className={classes.indent}>{pageText[11]}</p>
            <p>&nbsp;</p>
            {/* 6 */}
            <p><b>{pageText[12]}</b></p>
            <p className={classes.indent}>{pageText[13]}</p>
            <p>&nbsp;</p>
            {/* 7 */}
            <p><b>{pageText[14]}</b></p>
            <p className={classes.indent}>{pageText[15]}</p>
            <p>&nbsp;</p>
            {/* 8 */}
            <p><b>{pageText[16]}</b></p>
            <p className={classes.indent}>{pageText[17]}</p>
            <p>&nbsp;</p>
            
            <p>{pageText[18]}</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>

            
            <p>&nbsp;</p>

         </div>
         <Footer/>
    </Background>
  );
}

export default Privacy;
