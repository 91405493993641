// https://codepen.io/maheshambure21/pen/EozKKy
import React from 'react';

import classes from './InputLabelEffect.module.scss';

const InputLabelEffect = React.forwardRef((props, ref) => {
  return (
    // <div className={classes.inputContainer}>
    //   <label htmlFor={props.input.id}>{props.label}</label>
    //   <inputfocus_ ref={ref} {...props.input} />
    // </div>

      
        <div className={classes.inputContainer}>
        	<input ref={ref} {...props.input} className={classes.input} placeholder="Something"/>
            <label className={classes.label}>{props.label}</label>
            <span className={classes.bottomLine}></span>
        </div>
  );
});

export default InputLabelEffect;
