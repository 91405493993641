
const privacyText= {
    0:   {
       en:`Effective Date: 01.08.2023`,
       ro:`Data efectivă: 01.08.2023`
    },
    1:   {
       en:`Thank you for choosing the EdNeos Mobile App ("the App") and/or the EdNeos Web Platform ("the Web Platform"). This Privacy Policy explains how we collect, use, and safeguard your personal information when you use the App/Web Platform. By using the App/Web Platform, you consent to the practices described in this Privacy Policy. If you do not agree with the practices outlined below, please do not use the App/Web Platform.`,
       ro:`Vă mulțumim că ați ales aplicația mobilă EdNeos ("Aplicația") și/sau platforma web EdNeos ("Platforma Web"). Această Politică de Confidențialitate explică modul în care colectăm, utilizăm și protejăm informațiile dvs. personale atunci când utilizați Aplicația/Platforma Web. Prin utilizarea Aplicației/Platformei Web, sunteți de acord cu practicile descrise în această Politică de Confidențialitate. Dacă nu sunteți de acord cu practicile descrise mai jos, vă rugăm să nu utilizați Aplicația/Platforma Web.`
    },
    2:   {
       en:`1. Information We Collect:`,
       ro:`1. Informațiile pe care le colectăm:`
    },
    3:   {
       en:`We may collect personal information you provide directly to us, such as your name, email address, and other contact details when you sign up for an account or use certain features of the App/Web Platform. We also collect usage data, such as your interactions with the App/Web Platform and the content you create or access.`,
       ro:`Putem colecta informații personale pe care le furnizați direct nouă, cum ar fi numele dvs., adresa de email și alte detalii de contact, atunci când vă înregistrați pentru un cont sau utilizați anumite funcționalități ale Aplicației/Platformei Web. De asemenea, colectăm date de utilizare, cum ar fi interacțiunile dvs. cu Aplicația/Platforma Web și conținutul pe care îl creați sau la care aveți acces.`
    },
    4:   {
       en:`2. How We Use Your Information:`,
       ro:`2. Modul în care utilizăm informațiile Dvs.:`
    },
    5:   {
       en:`We use the information we collect to provide, maintain, and improve the App's/Web Platform's functionality and user experience. This includes personalizing content, communicating with you, and analyzing usage patterns to enhance our services.`,
       ro:`Utilizăm informațiile pe care le colectăm pentru a oferi, menține și îmbunătăți funcționalitatea și experiența utilizatorului a Aplicației/Platformei Web. Aceasta include personalizarea conținutului, comunicarea cu Dvs. și analizarea modelelor de utilizare pentru a îmbunătăți serviciile noastre.`
    },
    6:   {
       en:`3. Sharing of Your Information:`,
       ro:`3. Partajarea informațiilor Dvs.:`
    },
    7:   {
       en:`We may share your personal information with third-party service providers that assist us in providing the App's/Web Platform's services. We do not sell your personal information to third parties! We may also disclose your information in response to legal requests or to protect our rights, privacy, safety, or property.`,
       ro:`Putem partaja informațiile personale cu furnizorii de servicii terți care ne asistă în furnizarea serviciilor Aplicației/Platformei Web. Nu vindem informațiile dvs. personale terților? Putem dezvălui, de asemenea, informațiile Dvs. ca răspuns la cereri legale sau pentru a ne proteja drepturile, confidențialitatea, siguranța sau proprietatea.`
    },
    8:   {
       en:`4. Your Choices:`,
       ro:`4. Alegerile Dvs.:`
    },
    9:   {
       en:`You can review and update your account information through the App's/Web Platform's settings. You can also opt-out of receiving promotional communications from us by following the unsubscribe instructions provided in the emails.`,
       ro:`Puteți revizui și actualiza informațiile contului Dvs. prin setările Aplicației/Platformei Web. De asemenea, puteți renunța la primirea comunicărilor promoționale de la noi urmând instrucțiunile de dezabonare furnizate în emailuri.`
    },
    10:   {
       en:`5. Data Security:`,
       ro:`5. Securitatea datelor:`
    },
    11:   {
       en:`We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is completely secure.`,
       ro:`Luăm măsuri rezonabile pentru a proteja informațiile Dvs. personale împotriva accesului neautorizat, dezvăluirii, modificării sau distrugerii. Cu toate acestea, nici o metodă de transmitere pe internet sau de stocare electronică nu este complet sigură.`
    },
    12:   {
       en:`6. Children's Privacy:`,
       ro:`6. Confidențialitatea copiilor:`
    },
    13:   {
       en:`The app is collecting data with the sole purpose of setting up the account and managing it. We do not sell personal information to third parties. You can also opt out of your children's personal information from us by following the unsubscribe instructions provided in the emails. However, please keep in mind that this will imply closing your children's account on EDNEOS's platforms and losing all history!`,
       ro:`Aplicația colectează date cu unicul scop de a configura contul și de a-l gestiona. Nu vindem informații personale către terțe părți. De asemenea, puteți să renunțați la informațiile personale ale copiilor dumneavoastră de la noi, urmând instrucțiunile pentru dezabonare furnizate în email-uri. Cu toate acestea, vă rugăm să țineți cont că acest lucru va implica închiderea contului copiilor dumneavoastră de pe platformele EDNEOS și pierderea tuturor datelor acumulate în timp!`
    },
    14:   {
       en:`7. Changes to this Privacy Policy:`,
       ro:`7. Modificări ale acestei Politici de Confidențialitate:`
    },
    15:   {
       en:`We may update this Privacy Policy from time to time to reflect changes to our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes through the App/Web Platform or by other means before the changes take effect.`,
       ro:`Putem actualiza această Politică de Confidențialitate periodic pentru a reflecta modificări în practicile noastre sau din alte motive operaționale, legale sau reglementare. Vă vom notifica cu privire la orice modificări semnificative prin intermediul Aplicației/Platformei Web sau prin alte mijloace înainte ca modificările să între în vigoare.`
    },
    16:   {
       en:`8. Contact Us:`,
       ro:`8. Contactați-ne:`
    },
    17:   {
       en:`If you have any questions, concerns, or requests related to your personal information or this Privacy Policy, please contact us at hello@edneos.com.`,
       ro:`Dacă aveți întrebări, îngrijorări sau solicitări referitoare la informațiile Dvs. personale sau la această Politică de Confidențialitate, vă rugăm să ne contactați la adresa hello@edneos.com.`
    },
    18:   {
       en:`By using the EdNeos/Web Platform, you acknowledge that you have read, understood, and agreed to this Privacy`,
       ro:`Prin utilizarea Aplicației EdNeos/Platformei Web, recunoașteți că ați citit, înțeles și acceptat această Politică de Confidențialitate.`
    },
   
   };
   
   export default privacyText;
   

   
   