import classes from './TryApp.module.css';

import appIcon1 from "../../../../FILES/Images/appIcon1c.png";
import appIcon2 from "../../../../FILES/Images/appIcon2c.png";
import appIcon3 from "../../../../FILES/Images/appIcon3c.png";
import appStoreImg from "../../../../FILES/Images/AppStore.png";
import googlePlayImg from "../../../../FILES/Images/GooglePlay.png";

import AppCard from './Components/AppCard';
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText';
import ButtonWithImg from '../../../UI/Buttons/ButtonWithImg';
import ButtonWithArrow from '../../../UI/Buttons/ButtonWithArrow';
import SectionCard from '../../components/SectionCard';
import colors from '../../../../UTILS/colors';
import { scrollToSection } from '../../../../UTILS/scrollToSection';

function TryApp(props){ 
    const text = useGetTranslatedText('homePage_TextTranslations.js');
return (
    <SectionCard backgroundColor={colors.section} flexDirection={`column`} id={props.id}>
            <div className={classes.containerText}>                  
                <h1><span>{text[76]}</span> <span  style={{color:`rgb(66,82,253)`}}>{text[77]}</span> <span>{text[78]}</span> </h1>
                <div className={classes.par}>{text[79]}</div>        
            </div>
            <div className={classes.containerCards}>
                <AppCard name={text[80]} question={text[81]} picture={appIcon1} buttonText={text[94]} title={text[82]} textList ={[text[83], text[84], text[85]]} color={`rgb(197,114,241)`} backColor={`rgb(252,235,255)`}/> 
                <AppCard name={text[87]} question={text[88]} picture={appIcon2} buttonText={text[94]} title={text[89]} textList={[text[90], text[110], text[111]]}  color={`rgb(245,179,83)`} backColor={`rgb(255,246,213)`}/> 
                <AppCard name={text[91]} question={text[92]} picture={appIcon3} buttonText={text[94]} title={text[93]} textList={[text[112], text[113], text[114]]} color={`rgb(21,212,119)`} backColor={`rgb(220,250,235)`}/>
            </div>
            <div className={classes.installAppButtons}>
                {/* <ButtonWithImg imgSrc={googlePlayImg}/> */}                
                {/* <ButtonWithImg imgSrc={appStoreImg}/>     */}
                <ButtonWithArrow  onClick={() =>scrollToSection('contact')}><span>{text[115]}</span></ButtonWithArrow>
            </div>         
    </SectionCard> 

);
}


export default TryApp;