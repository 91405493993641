// import "../../assets/css/style.css";
// import "../../App.css";

import {ScrollTo, ScrollArea} from "react-scroll-to";
import EdNeosHome from "../../COMPONENTS/forHomePage/sections/EdNeosHome/EdNeosHome";
import ForThoseWhoStudy from '../../COMPONENTS/forHomePage/sections/ForThoseWhoStudy';
import ForThoseWhoTeach from '../../COMPONENTS/forHomePage/sections/ForThoseWhoTeach';
import Contact from '../../COMPONENTS/forHomePage/sections/Contact';
import Benefits from "../../COMPONENTS/forHomePage/sections/Benefits/Benefits";
import Testimonials from "../../COMPONENTS/forHomePage/sections/Testimonials/Testimonials";
import TryApp from "../../COMPONENTS/forHomePage/sections/TryApp/TryApp";
import Questions from "../../COMPONENTS/forHomePage/sections/Questions/Questions";

import Footer from '../../COMPONENTS/UI/Footer/Footer';

function HomePage() {
      
    // 'why_edneos')}>{text[4]}</a>
    // 'product')}>{text[5]}</a>
    // 'solution')}>{text[6]}</a>
    // 'our_vision') }>{text[7]}</a>
    // 'contact')}>{text[8]}</a>

    return (
        <ScrollTo>
            {({scroll}) => (
                <ScrollArea>
                    <div > 
                        <EdNeosHome id={'why_edneos'}/>
                        <Benefits />
                        <ForThoseWhoStudy id={'products'}/>
                        <ForThoseWhoTeach id={'solution'}/>
                        <Testimonials />
                        <Questions id={'our_vision'}/>
                        <TryApp id={'pricing'}/>
                        <Contact id={'contact'}/>
                        <Footer/>
                    </div>
                </ScrollArea>
            )}
        </ScrollTo>
    );
}

export default HomePage;
