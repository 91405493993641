import useGetTranslatedText from '../../../HOOKS/useGetTranslatedText'; 
import { Link } from 'react-router-dom';
import classes from './Footer.module.css';

function Footer(){ 
    const text = useGetTranslatedText('general_TextTranslations.js');
    const link = useGetTranslatedText('links_TextTranslations.js');

return(    
    <section className={classes.footerx}>             
        <div className={classes.container}>
            <p>{text[0]}</p>
            <Link to={`/${link[0]}`}>
                <div className="terms-and-conditions" style={{color: 'black'}}> {text[1]}</div>
            </Link>
            <Link to={`/${link[1]}`}>
                <div className="terms-and-conditions" style={{color: 'black'}}> {text[2]}</div>
            </Link>
            <p>{text[3]} <b>Bogdan Toma Agency</b></p>
        </div>
    </section>
);
};

export default Footer;