import { useEffect } from 'react';

const useClickOutside = (ref, callback, ignoreRef) => {
    const handleClickOutside = (event) => {
        if (
            ref.current &&
            !ref.current.contains(event.target) &&
            (!ignoreRef || !ignoreRef.current || !ignoreRef.current.contains(event.target))
        ) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback, ignoreRef]);
};

export default useClickOutside;