
 
import classes from './ButtonWithArrow.module.scss';
import SvgArrow2 from '../../SVG/SvgArrow2';

const ButtonWithArrow = (props) => {
  return (
    <button
      className={classes.button}
      type={props.type || 'button'}
      onClick={props.onClick}
    >
      {props.children}<SvgArrow2/>
    </button>
  );
};

export default ButtonWithArrow;
