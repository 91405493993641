import useGetTranslatedText from '../../../HOOKS/useGetTranslatedText'; 
import learn_img2 from "../../../assets/images/learn-img-2.png";


function ForThoseWhoTeach(props){ 
    const text = useGetTranslatedText('homePage_TextTranslations.js');
return (
    <section id="solution"  className=" is-fullheight learning-container">
    <div className="learning-column">
        <img src={learn_img2} className="learn-img-1"/>
    </div>
    <div className="learning-column" >
        <h1>{text[14]}</h1>
        <p>{text[15]}</p>
        <hr/>
        <div className="learning-functionalities">
            <div className="learning-card">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="60" height="60" rx="30" fill="#ECEDFF"/>
                    <g clipPath="url(#clip0_487_1503)">
                        <path d="M24.1047 35.3088L17.6472 32.9338C17.4573 32.8637 17.2935 32.7371 17.1778 32.5711C17.062 32.405 17 32.2075 17 32.0051C17 31.8027 17.062 31.6051 17.1778 31.4391C17.2935 31.273 17.4573 31.1464 17.6472 31.0763L24.1047 28.7013C24.2391 28.6521 24.3612 28.5741 24.4625 28.4729C24.5637 28.3716 24.6417 28.2495 24.6909 28.1151L27.0659 21.6576C27.136 21.4677 27.2626 21.3038 27.4287 21.1881C27.5947 21.0724 27.7923 21.0104 27.9947 21.0104C28.1971 21.0104 28.3946 21.0724 28.5607 21.1881C28.7267 21.3038 28.8533 21.4677 28.9234 21.6576L31.2984 28.1151C31.3477 28.2495 31.4256 28.3716 31.5269 28.4729C31.6281 28.5741 31.7502 28.6521 31.8847 28.7013L38.3422 31.0763C38.5321 31.1464 38.6959 31.273 38.8116 31.4391C38.9273 31.6051 38.9894 31.8027 38.9894 32.0051C38.9894 32.2075 38.9273 32.405 38.8116 32.5711C38.6959 32.7371 38.5321 32.8637 38.3422 32.9338L31.8847 35.3088C31.7502 35.358 31.6281 35.436 31.5269 35.5372C31.4256 35.6385 31.3477 35.7606 31.2984 35.8951L28.9234 42.3526C28.8533 42.5424 28.7267 42.7063 28.5607 42.822C28.3946 42.9377 28.1971 42.9997 27.9947 42.9997C27.7923 42.9997 27.5947 42.9377 27.4287 42.822C27.2626 42.7063 27.136 42.5424 27.0659 42.3526L24.6909 35.8951C24.6417 35.7606 24.5637 35.6385 24.4625 35.5372C24.3612 35.436 24.2391 35.358 24.1047 35.3088Z" stroke="#4353FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M36 16V22" stroke="#4353FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M42 23V27" stroke="#4353FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M33 19H39" stroke="#4353FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M40 25H44" stroke="#4353FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_487_1503">
                            <rect width="32" height="32" fill="white" transform="translate(14 14)"/>
                        </clipPath>
                    </defs>
                </svg>
                <h3>{text[16]}</h3>
                <p>{text[17]}</p>
            </div>
            <div className="learning-card">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="60" height="60" rx="30" fill="#FFF6D5"/>
                    <g clipPath="url(#clip0_487_1515)">
                        <path d="M30 42C36.6274 42 42 36.6274 42 30C42 23.3726 36.6274 18 30 18C23.3726 18 18 23.3726 18 30C18 36.6274 23.3726 42 30 42Z" stroke="#F68800" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M30 35C32.7614 35 35 32.7614 35 30C35 27.2386 32.7614 25 30 25C27.2386 25 25 27.2386 25 30C25 32.7614 27.2386 35 30 35Z" stroke="#F68800" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M26.4646 26.465L21.5146 21.515" stroke="#F68800" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M33.5352 26.465L38.4852 21.515" stroke="#F68800" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M33.5352 33.535L38.4852 38.485" stroke="#F68800" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M26.4646 33.535L21.5146 38.485" stroke="#F68800" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_487_1515">
                            <rect width="32" height="32" fill="white" transform="translate(14 14)"/>
                        </clipPath>
                    </defs>
                </svg>

                <h3>{text[18]}</h3>
                <p>{text[19]}</p>
            </div>
            <div className="learning-card">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="60" height="60" rx="30" fill="#FFEAE9"/>
                    <g clipPath="url(#clip0_487_1529)">
                        <path d="M30 42V35" stroke="#F73E5E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M18 30H25" stroke="#F73E5E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M25.8188 27.095C25.1863 27.98 24.9438 29.0775 25.0063 30.26C25.0555 31.2015 25.37 32.1098 25.9133 32.8803C26.4567 33.6507 27.2068 34.2519 28.0771 34.6143C28.9474 34.9768 29.9024 35.0858 30.832 34.9288C31.7617 34.7718 32.6279 34.3552 33.3309 33.727C34.0339 33.0989 34.545 32.2847 34.8052 31.3786C35.0654 30.4724 35.0641 29.5112 34.8015 28.6057C34.5389 27.7003 34.0256 26.8875 33.3209 26.2613C32.6162 25.635 31.7488 25.2207 30.8188 25.0662C30.5881 25.0257 30.3793 24.9048 30.2294 24.7249C30.0795 24.545 29.9983 24.3179 30 24.0837V19C29.9995 18.8613 30.0279 18.724 30.0833 18.5969C30.1387 18.4698 30.2199 18.3556 30.3219 18.2615C30.4238 18.1675 30.5441 18.0957 30.6753 18.0506C30.8065 18.0056 30.9456 17.9884 31.0838 18C37.2588 18.5537 42.0838 23.8275 41.9988 30.1675C41.9088 36.63 36.6338 41.905 30.1713 41.995C28.5815 42.0177 27.003 41.7242 25.5276 41.1316C24.0522 40.539 22.7093 39.6592 21.5768 38.5431C20.4444 37.4271 19.545 36.0971 18.931 34.6305C18.317 33.1639 18.0005 31.5899 18 30C17.9959 27.2066 18.9788 24.5017 20.775 22.3625C20.8637 22.2556 20.9738 22.1683 21.0981 22.1063C21.2224 22.0443 21.3583 22.0089 21.4971 22.0023C21.6359 21.9957 21.7745 22.0181 21.9041 22.0681C22.0338 22.118 22.1516 22.1945 22.25 22.2925L25.7 25.8175C25.8665 25.9827 25.9696 26.2011 25.9913 26.4347C26.013 26.6682 25.9519 26.9019 25.8188 27.095Z" stroke="#F73E5E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_487_1529">
                            <rect width="32" height="32" fill="white" transform="translate(14 14)"/>
                        </clipPath>
                    </defs>
                </svg>

                <h3>{text[20]}</h3>
                <p>{text[21]}</p>
            </div>
            <div className="learning-card">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="60" height="60" rx="30" fill="#DCFEEE"/>
                    <g clipPath="url(#clip0_487_1539)">
                        <path d="M18 36L30 43L42 36" stroke="#15D477" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M18 30L30 37L42 30" stroke="#15D477" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M18 24L30 31L42 24L30 17L18 24Z" stroke="#15D477" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_487_1539">
                            <rect width="32" height="32" fill="white" transform="translate(14 14)"/>
                        </clipPath>
                    </defs>
                </svg>

                <h3>{text[22]}</h3>
                <p>{text[23]}</p>
            </div>
            <div className="learning-card">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="60" height="60" rx="30" fill="#E7F6FF"/>
                    <g clipPath="url(#clip0_487_1550)">
                        <path d="M20.8075 39.1925C19.6575 38.0425 20.42 35.6263 19.835 34.2113C19.2275 32.75 17 31.5625 17 30C17 28.4375 19.2275 27.25 19.835 25.7887C20.42 24.375 19.6575 21.9575 20.8075 20.8075C21.9575 19.6575 24.375 20.42 25.7887 19.835C27.2562 19.2275 28.4375 17 30 17C31.5625 17 32.75 19.2275 34.2113 19.835C35.6263 20.42 38.0425 19.6575 39.1925 20.8075C40.3425 21.9575 39.58 24.3737 40.165 25.7887C40.7725 27.2562 43 28.4375 43 30C43 31.5625 40.7725 32.75 40.165 34.2113C39.58 35.6263 40.3425 38.0425 39.1925 39.1925C38.0425 40.3425 35.6263 39.58 34.2113 40.165C32.75 40.7725 31.5625 43 30 43C28.4375 43 27.25 40.7725 25.7887 40.165C24.375 39.58 21.9575 40.3425 20.8075 39.1925Z" stroke="#48A8FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M25 31L28 34L35 27" stroke="#48A8FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_487_1550">
                            <rect width="32" height="32" fill="white" transform="translate(14 14)"/>
                        </clipPath>
                    </defs>
                </svg>


                <h3>{text[24]}</h3>
                <p>{text[25]}</p>
            </div>
            <div className="learning-card">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="60" height="60" rx="30" fill="#FCEBFF"/>
                    <g clipPath="url(#clip0_487_1559)">
                        <path d="M26 37C26 37 25 41 19 41C19 35 23 34 23 34" stroke="#B244ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M37.8888 28.1112C40.8888 25.1112 41.0763 21.5424 40.9825 19.9512C40.9669 19.7087 40.8635 19.4801 40.6917 19.3083C40.5198 19.1364 40.2913 19.033 40.0487 19.0174C38.4575 18.9237 34.8913 19.1087 31.8888 22.1112L24 29.9999L30 35.9999L37.8888 28.1112Z" stroke="#B244ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M31.0003 23H23.2941C23.0292 23.0001 22.7752 23.1053 22.5878 23.2925L18.2941 27.5863C18.163 27.7176 18.071 27.8829 18.0285 28.0636C17.986 28.2442 17.9946 28.4332 18.0534 28.6092C18.1122 28.7853 18.2188 28.9415 18.3613 29.0604C18.5038 29.1793 18.6766 29.2562 18.8603 29.2825L24.0003 30" stroke="#B244ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M37 29V36.7062C36.9999 36.9711 36.8947 37.2251 36.7075 37.4125L32.4137 41.7062C32.2824 41.8373 32.1171 41.9293 31.9364 41.9718C31.7558 42.0143 31.5668 42.0057 31.3908 41.9469C31.2147 41.8881 31.0585 41.7815 30.9396 41.639C30.8207 41.4965 30.7438 41.3237 30.7175 41.14L30 36" stroke="#B244ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_487_1559">
                            <rect width="32" height="32" fill="white" transform="translate(14 14)"/>
                        </clipPath>
                    </defs>
                </svg>


                <h3>{text[26]}</h3>
                <p>{text[27]}</p>
            </div>
        </div>
    </div>
</section>    
    );
}


export default ForThoseWhoTeach;