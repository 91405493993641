const homePageTextTranslations= {
   0: {
         en:`For those who study`,
         ro:`Pentru cei care învață`
      },
   1: {
         en:`The mobile application is intended for pupils, students and employees, and is available on the Apple App Store, Google Play Store. You have all the reasons to download it:`,
         ro:`Aplicația mobile este destinată elevilor, studenților și angajaților, și este disponibilă pe Apple App Store, Google Play Store. Ai toate motivele pentru care să o descarci:`
      },
   /////////////////ForThoseWhoStudy//////////////
   2: {
        en:`Customized Curriculum`,
        ro:`Curriculum personalizat`
      },         
   3: {
         en:`Creating an individual-centered educational journey`,
         ro:`Crearea parcursului educațional centrat pe individ`
      },
   4: {
         en:`Non-stop counseling`,
         ro:`Consiliere non stop`
      },
   5: {
         en:`Ask and receive answers whenever needed`,
         ro:`Întreabă și a primește răspunsuri oricând e nevoie`
      },
   6: {
         en:`Memory algorithms`,
         ro:`Algoritme de memorare`
      },
   7: {
         en:`Innovative methods for retaining large volumes of information in record time`,
         ro:`Metode inovative de reținere a volumelor mari de informații în timp record`
      },
   8: {
         en:`Learning cards`,
         ro:`Carduri de învățare`
      },
   9: {
         en:`Transform boring information into interactive cards for easier memorization`,
         ro:`Transformă informațiile plictisitoare în carduri interactive pentru a le memora mai ușor`
      },
   10:{
         en:`Complete learning guarantee`,
         ro:`Garanția învățării complete`
      },
   11:{
         en:`Learned information is assimilated for a lifetime`,
         ro:`Informațiile învățate sunt însușite pentru întreaga viață`
      },
   12:{
         en:`Lifelong Learning concept`,
         ro:`Conceptul Lifelong Learning`
      },
   13:{
         en:`Adapt educational materials according to the specificities of each individual`,
         ro:`Adaptează materialele educaționale în funcție de particularitățile fiecărui individ`
      }, 
/////////////////ForThoseWhoTeach//////////////
   14:{
         en:`For those who teach`,
         ro:`Pentru cei care predau`
      },
   15:{
         en:`The web platform is intended for school organizations, companies, but also mentors and teachers. Choose your type of account and benefit from:`,
         ro:`Platforma web este destinată organizațiilor școlare, companiilor, dar și mentorilor și profesorilor. Alege tipul de cont și beneficiază de:`
      },
   16:{
         en:`Knowledge Audit`,
         ro:`Auditul cunoștințelor`
      },
   17:{
         en:`Knowledge audit. Evaluate learners and develop innovative education programs without involving trainers.`,
         ro:`Knowledge audit. Evaluează cursanții şi elaborează programe de educație inovatoare fără implicarea trainerilor.`
      },
   18:{
         en:`AI-generated Content`,
         ro:`Conținut creat de AI`
      },
   19:{
         en:`Artificial intelligence helps you have the most suitable content - easily and without additional costs.`,
         ro:`Inteligența artificială te ajută să ai cel mai potrivit conținut - ușor și fără costuri suplimentare.`      
      },    
   20:{
         en:`Complete Learning Guarantee`,
         ro:`Garanția învățării complete`
      },
   21:{
         en:`Guarantee learner performance and better results in evaluations and exams.`,
         ro:`Garantează performanțele cursanților și rezultate mai bune la evaluări și examene.`
      },
   22:{
         en:`AI-based Knowledge Management`,
         ro:`Managementul cunoștințelor prin AI`
      },
   23:{
         en:`Upload PDF and DOCX files into the system, so the assistant is trained to answer learners correctly.`,
         ro:`Încarcă PDF și DOCX în sistem, astfel asistentul este instruit să răspundă corect cursanților.`
      },
   24:{
         en:`Notifications and Communication`,
         ro:`Notificari și comunicare`
      },
   25:{
         en:`Motivate learners through experience transfer and information.`,
         ro:`Motivarea cursanților prin transfer de experiență și informare.`
      },
   26:{
         en:`Vocal Educational Assistant`,
         ro:`Asistentul educațional vocal`
      },
   27:{
         en:`Communicate by voice and recognize voice responses provided by the learners, saving time for creativity.`,
         ro:`Comunică prin voce și recunoaște răspunsuri vocale oferite de cursanți, economisind timpul pentru creativitate.`
      },            
//////////////// Contact ////////////////////
   28:{
         en:`Do you have questions or collaboration proposals?`,
         ro:`Ai întrebări sau propuneri de colaborare?`
   }, 
   29:{
         en:`We are open to communication!`,
         ro:`Suntem deschiși spre comunicare!`
      }, 
   30:{
         en:`Contact us at`,
         ro:`Contactează-ne pe`
      }, 
   31:{
         en:`FIRST NAME`,
         ro:`Prenume`
      }, 
   32:{
         en:`Enter your name`,
         ro:`Introduceți prenumele dvs.`
      }, 
   33:{
         en:`LAST NAME`,
         ro:`Numele de familie`
      }, 
   34:{
         en:`Enter your last name`,
         ro:`Introduceți numele de familie`
      }, 
   35:{
         en:`E-MAIL ADDRESS`,
         ro:`Adresa de email`
      }, 
   36:{
         en:`Enter your e-mail address`,
         ro:`Introduceți adresa dvs. de e-mail`
      }, 
   37:{
         en:`PHONE NUMBER`,
         ro:`Numar de telefon`
      }, 
   38:{
         en:`Enter your phone number`,
         ro:`Introduceți numarul dvs. de telefon`
      },    
   39:{
         en:`MESSAGE`,
         ro:`Mesaj`
      }, 
   40:{
         en:`Enter your message`,
         ro:`Introduceți mesajul`
      }, 
   41:{
         en:`Send message`,
         ro:`Trimite mesaj`
      },
   
   //////////////// Section Home //////////////////////
   42:{
         en:`NAVIGATE THE OCEAN OF KNOWLEDGE WITH`,
         ro:`NAVIGHEAZĂ OCEANUL CUNOȘTINȚELOR CU`
      }, 
   43:{
         en:`EdNeos`,
         ro:`EdNeos`
      }, 
   44:{
         en:`: your AI co-pilot and educational assistant`,
         ro:`: co-pilotul și asistentul tău educațional AI`
      }, 
   45:{
         en:`EdNeos makes it possible for each learner to have their own mentor, teacher and tutor available 24/7, to help them transform information into knowledge that will never be forgotten.`,
         ro:`Cu EdNeos fiecare cursant are propriul mentor, profesor și tutor disponibil 24/7, care îl ajuta sa transforme informații în cunoștințe ce nu vor fi uitate niciodată.`
      }, 
   46:{
         en:`Neuroscience`,
         ro:`Neuroștiințe`
      }, 
   47:{
         en:`Artificial Intelligence`,
         ro:`Inteligență Artificială`
      }, 
   48:{
         en:`Innovative Algorithms`,
         ro:`Algoritmi Inovatori`
      },                      
   49:{
         en:`assimilation<br><b>speed</b>`,
         ro:`<b>viteză</b><br> de asimilare`
      }, 
   50:{
         en:``,
         ro:``
      }, 
   51:{
         en:`memory<br><b>retention</b>`,
         ro:`<b>reținere</b><br>a memoriei`
      }, 
   52:{
         en:``,
         ro:``
      }, 
   53:{
         en:`learning<br><b>efficiency</b>`,
         ro:`<b>eficiență</b><br>în învățare`
      },  
   54:{
         en:``,
         ro:``
      }, 

//////////////// Section Benefits //////////////////////      
   55:{
         en:`Assimilate more.`,
         ro:`Asimilează mai mult.`
      }, 
   56:{
         en:`Progress faster.`,
         ro:`Progresează mai rapid.`
      }, 
   57:{
         en:`Personalized AI Curriculum`,
         ro:`Curriculum personalizat AI`
      }, 
   58:{
         en:`AI Educational Tutor`,
         ro:`AI tutore educațional`
      },  
   59:{
         en:`Use Learning Cards`,
         ro:`Folosește cardurile de învățare`
      },  
   60:{
         en:`Utilize Voice in Learning`,
         ro:`Utilizează vocea în învățare`
      },  
   61:{
         en:`Retrieve Forgotten Knowledge`,
         ro:`Recuperează cunoștințele uitate`
      },   
   62:{
         en:`A personalized educational journey through 5 levels of mastery: from Familiarization to Real-Life Application.`,
         ro:`Parcurs educațional personalizat prin 5 niveluri de stăpânire: de la Familiarizare la Aplicare în viață.`
      },    
   63:{
         en:`Receive answers and assistance for any question, anytime you need it, wherever you are.`,
         ro:`Primește răspunsuri și asistență la orice întrebare, oricând ai nevoie, oriunde te-ai afla.`
      }, 
   64:{
         en:`Transform large volumes of information into interactive cards for lasting and deep memorization.`,
         ro:`Transformă volumele mari de informații în carduri interactive pentru o memorare durabilă și profundă.`
      },
   65:{
         en:`Communicate with your virtual mentor: tell them what you know, ask them what you don't know, and receive personalized answers.`,
         ro:`Comunică cu mentorul tău virtual: spune-i ce cunoști, intreaba-l ce nu știi, și primește răspunsuri personalizate.`
      },
   66:{
         en:`Evaluate the depth of your understanding or the degree of material mastery and refresh your forgotten notions.`,
         ro:`Evaluează-ți profunzimea înțelegerii sau gradul de însușire a materialelor și reînnoiește-ți noțiunile uitate.`
      },
//////////////// Section Testimonials //////////////////////            
   67:{
         en:`How does`,
         ro:`Cum transformă`
      },  
   68:{
         en:`EdNeos`,
         ro:`EdNeos`
      }, 
   69:{
      en:`transform the educational experience?`,
      ro:`experiența educațională?`
   }, 
   70:{
      en:`medical student`,
      ro:`student la medicină`
   }, 
   71:{
      en:`high school student`,
      ro:`elevă de liceu`
   }, 
   72:{
      en:`teacher`,
      ro:`profesor`
   }, 
   73:{
      en:`“I use EdNeos because I am constantly faced with the challenge of quickly assimilating a large volume of medical terms and concepts. I need to memorize thousands of notions in a short span of time. EdNeos helps me do exactly that, offering me an optimized learning method.”`,
      ro:`“Folosesc EdNeos pentru că mă confrunt constant cu provocarea de a asimila rapid un volum mare de termeni și concepte medicale. Am nevoie să memorez mii de noțiuni într-un interval scurt de timp. EdNeos mă ajută să fac exact acest lucru, oferindu-mi o metodă optimizată de învățare.”`
}, 
   74:{
      en:`“Often, I'm not aware of my learning gaps. I used to take private tutoring sessions to discover what I don't know and to catch up on the material. I've given up on tutoring because EdNeos points out any notion I've forgotten and shows me how to recover the lost information. It's a mentor available 24/7.”`,
      ro:`“Adesea, nu sunt conștientă de lacunele mele în învățare. Eram nevoită să iau meditații pentru a descoperi ce nu știu și pentru a recupera materia. Am renunțat la meditații, deoarece EdNeos îmi indică orice noțiune pe care am uitat-o și îmi arată cum să recuperez informațiile pierdute. Este un mentor disponibil 24/7.”` 
},
   75:{
      en:`“I want to know where my students need help, either individually or as a group. I'm looking for concrete data and want a guarantee of learning efficiency. I recommend my students use the app, and I use the web version to have precise data. This gives me confidence that the learning process is deep and lasting.”`,
      ro:`“Vreau să știu unde studenții mei au nevoie de ajutor, fie individual, fie ca grup. Sunt în căutarea unor date concrete și doresc o garanție a eficienței învățării. Recomand elevilor mei să utilizeze aplicația, iar eu mă folosesc de versiunea web pentru a avea date exacte. Acest lucru îmi oferă încrederea că procesul de învățare este profund și durabil.”`
}, 
//////////////// Section TryApp //////////////////////                                            
   76:{
         en:`Try`,
         ro:`Try`
      }, 
   77:{
         en:`Edneos`,
         ro:`Edneos`
      }, 
   78:{
         en:`App for free`,
         ro:`App for free`
      }, 
   79:{
         en:`We share a vision and commitment to help each other — and our customers — create unique learning experiences.`,
         ro:`We share a vision and commitment to help each other — and our customers — create unique learning experiences.`
      }, 
   80:{
         en:`Account for companies`,
         ro:`Cont pentru companii`
      }, 
   81:{
         en:`Do you want to teach your employees?`,
         ro:`Vrei să predai angajaților?`
      }, 
   82:{
         en:`Benefits for Institutions:`,
         ro:`Beneficii pentru Instituții:`
      }, 
   83:{
         en:`Saves time and training costs`,
         ro:`Economie de timp și costuri pentru instruire`
      }, 
   84:{
         en:`Monitoring the educational progress of learners`,
         ro:`Monitorizarea evoluției educaționale a cursanților`
      },
   85:{
         en:`Support in creating educational content`,
         ro:`Suport în crearea conținutului educațional`
      },
   86:{
         en:``,
         ro:``
      },
   87:{
         en:`Account for mentors`,
         ro:`Cont pentru mentori`
      },  
   88:{
         en:`Do you want to teach students?`,
         ro:`Vrei să predai elevilor?`
      },
   89:{
         en:`Benefits for teachers:`,
         ro:`Beneficii pentru profesori:`
      },
   90:{
         en:`More time for creativity and students`,
         ro:`Mai mult timp pentru creativitate și studenți`
      },
   110:{
         en:`Guarantee of student performance`,
         ro:`Garanția performanței elevilor`
      },
   111:{
         en:`Suggestions for presentations, lessons, and tasks`,
         ro:`Sugestii pentru prezentări, lecții și sarcini`
      },          
   91:{
         en:`Account for students`,
         ro:`Cont pentru studenți`
      },
   92:{
         en:`Do you want to learn something new?`,
         ro:`Vrei să înveți ceva nou?`
      },
   93:{
         en:`Benefits for learners:`,
         ro:`Beneficii pentru cei care învață:`
      },
   112:{
         en:`Eliminates the costs associated with tutoring`,
         ro:`Eliminarea costurilor asociate meditațiilor`
      },
   113:{
         en:`Autonomy over their own knowledge`,
         ro:`Autonomie asupra propriilor cunoștințe`
      },
   114:{
         en:`Retention of knowledge with an efficiency of 300%`,
         ro:`Reținerea cunoștințelor cu o eficiență de 300%`
      },


   94:{
         en:`Create account`,
         ro:`Create account`
      },                                        
//////////////// Section Questions //////////////////////
   95:{
         en:`Frequently asked Questions`,
         ro:`Întrebări frecvente`
      }, 
   96:{
         en:`We share a vision and commitment to help each other — and our customers — create unique learning experiences.`,
         ro:`We share a vision and commitment to help each other — and our customers — create unique learning experiences.`
      },       
   97:{
         en:`What is EdNeos?`,
         ro:`Ce este EdNeos?`
   },       
   98:{
         en:`Who is EdNeos for?`,
         ro:`Pentru cine este EdNeos?`
   },      
   99:{
         en:`How does EdNeos work?`,
         ro:`Cum funcționează EdNeos?`
      },       
   100:{
         en:`What subjects can you learn with EdNeos?`,
         ro:`Ce subiecte poți învăța cu EdNeos?`
      },       
   101:{
         en:`How can I start learning with EdNeos?`,
         ro:`Cum începi să înveți cu EdNeos?`
      },       
   102:{
         en:`EdNeos is the first AI educational assistant that guarantees complete learning.`,
         ro:`EdNeos este primul asistent educațional AI care garantează învățarea completă.`
      },       
   103:{
         en:`EdNeos can be useful not only to students and teachers but also to companies that need to train staff, as well as educational organizations that want higher performance from students.`,
         ro:`EdNeos poate fi de folos nu doar studenților și profesorilor, ci și companiilor care au nevoie să instruiască personalul, precum și organizațiilor educaționale care își doresc mai multe performanțe de la studenți.`
      },       
   104:{
         en:`Through the mobile application, Memory Algorithms, and Web Platform, EdNeos facilitates deep and lasting learning, using the power of data, neuroscience, and AI.`,
         ro:`Prin aplicația mobilă, Algoritmii de memorare și Platforma web, EdNeos facilitează învățarea profundă și durabilă, folosind puterea datelor, neuroștiinței și AI.`
      },       
   105:{
         en:`You can learn any subjects, as using the EdNeos solution, both the mentor and the student can generate content according to their needs.`,
         ro:`Poți învăța orice subiecte, întrucât folosind soluția EdNeos, atât mentorul, cât și studentul pot genera conținut conform nevoilor lor.`
      },       
   106:{
         en:`To start the learning process, register by selecting the student account. You will receive access to your EdNeos account and all usage instructions, then you will be able to test our solution among the first.`,
         ro:`Pentru a începe procesul de învățare, înregistrează-te, selectând contul de student. Vei primi acces la contul tău EdNeos și toate instrucțiunile de utilizare și vei putea să testezi soluția noastră printre primii.`
      }, 
   107:{
         en:`NAME`,
         ro:`Nume`
      }, 
   108:{
         en:`Enter your full name`,
         ro:`Introduceți numele dvs. complet`
      },  
   109:{
         en:`Forget less.`,
         ro:`Uită mai puțin.`
      },  
   115:{
         en:`Waiting list`,
         ro:`Listă de așteptare`
      },       
      
};

    
    export default homePageTextTranslations;