import React from 'react';

function SvgArrow1() {
    return (
        <svg style={{marginLeft: 10}} width="10" height="16" viewBox="0 0 10 16" fill="none">
            <mask id="mask0_257_278" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="4" width="10" height="10">
                <path d="M1 13L9 5M9 5H2M9 5V12" stroke="#212121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </mask>
            <g mask="url(#mask0_257_278)">
                <rect x="-5" y="-2" width="20" height="20" fill="#4353FF"/>
            </g>
        </svg>  
    );
};

export default React.memo(SvgArrow1);