import classes from './EdNeosHome.module.css';
import boyAI from "../../../../FILES/Boy&AI.png";
import appStoreImg from "../../../../FILES/Images/AppStore.png";
import googlePlayImg from "../../../../FILES/Images/GooglePlay.png";
import ButtonWithImg from '../../../UI/Buttons/ButtonWithImg';
import ButtonWithArrow from '../../../UI/Buttons/ButtonWithArrow';
import { scrollToSection } from '../../../../UTILS/scrollToSection';
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText'; 
import SectionCard from '../../components/SectionCard';
import colors from '../../../../UTILS/colors';
import DOMPurify from 'dompurify';

function EdNeosHome(props){ 
    const text = useGetTranslatedText('homePage_TextTranslations.js');
    const sanitizedDataText49 = () => ({__html: DOMPurify.sanitize(text[49]) });
    const sanitizedDataText51 = () => ({__html: DOMPurify.sanitize(text[51]) });
    const sanitizedDataText53 = () => ({__html: DOMPurify.sanitize(text[53]) });
return (
    <SectionCard backgroundColor={colors.section} textAlign={`left`} id={props.id}>
            <div className={classes.containerText}>
                <h3>{text[42]}</h3>
                <h1 className={classes.h1}><span  style={{color:`rgb(66,82,253)`}}>{text[43]}</span><span>{text[44]}</span></h1>
                <div className={classes.pp}>{text[45]}</div>

                <div className={classes.containerPerformance}>
                    <div className={classes.containerTitles}>
                        <div className={classes.title} style={{color:`rgb(162,1,255)`, backgroundColor:`rgb(242,218,255)`}}>{text[46]}</div>
                        <div className={classes.title} style={{color:`rgb(255,143,1)`, backgroundColor:`rgb(255,227,194)`}}>{text[47]}</div>
                        <div className={classes.title} style={{color:`rgb(1,191,99)`, backgroundColor:`rgb(180,255,219)`}}>{text[48]}</div>
                    </div> 
                    
                    <div className={classes.line}><hr/></div>                     
                    <div className={classes.arrowDown}></div> 
                    
                    <div className={classes.containerResults}>
                        <div className={classes.result}>
                            <div className={classes.value} style={{color:`rgb(162,1,255)`}}>2x</div>                            
                            <div className={classes.text1} dangerouslySetInnerHTML={sanitizedDataText49()} />
                        </div>
                        <div className={classes.result}>
                        <div className={classes.value} style={{color:`rgb(246,135,1)`}}>3x</div>
                            <div className={classes.text1} dangerouslySetInnerHTML={sanitizedDataText51()} />                            
                        </div>
                        <div className={classes.result}>
                        <div className={classes.value} style={{color:`rgb(1,191,99)`}}>2x</div>
                            <div className={classes.text1} dangerouslySetInnerHTML={sanitizedDataText53()} />
                        </div>
                    </div>                                
                </div>                                
            </div>
            <div className={classes.containerImg}>  
                <img className={classes.img} src={boyAI} alt={'EdNeos logo robot'}/>    
                <div className={classes.installAppButtons}>
                    {/* <ButtonWithImg imgSrc={googlePlayImg}/>
                    <ButtonWithImg imgSrc={appStoreImg}/>     */}
                    <ButtonWithArrow  onClick={() =>scrollToSection('contact')}><span>{text[115]}</span></ButtonWithArrow>
                </div>
            </div>
        
    </SectionCard> 

);
}


export default EdNeosHome;