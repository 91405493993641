
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import iOS from "../../UTILS/iOS";

function Redirect() {
    const params = useParams();
    const { refId }= params;
    const OS = iOS();

    useEffect(() => {
        if (OS) {
            console.log('Detected iOS');
            window.location.href = "https://apps.apple.com/md/app/edneos-your-ai-copilot/id6468275413?referral="+encodeURIComponent(refId);
        }else{
            console.log('Detected Android');            
            window.location.href = "https://play.google.com/store/apps/details?id=com.edneos&pcampaignid=web_share&referral="+encodeURIComponent(refId);
        };
    }, []);    
};

export default Redirect;