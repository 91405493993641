import classes from './Header.module.css';
import homePageText from '../../../translationLibrary/homePage_TextLibrary';

import React, {useState, useEffect,  useRef} from 'react';

import "../../../assets/css/bulma.min.css";
import "../../../assets/css/style.css";
import "../../../App.css";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import SvgArrow1 from '../../SVG/SvgArrow1';

import useClickOutside from '../../../HOOKS/useClickOutside';
import {scrollToSection} from '../../../UTILS/scrollToSection';
import { Link } from 'react-router-dom';


import { useDispatch, useSelector } from 'react-redux';
import { storeGeneral_Actions } from '../../../STORE/storeGeneral';
import useGetTranslatedText from '../../../HOOKS/useGetTranslatedText'; 

 
function Header(props){ 
    const text = useGetTranslatedText('general_TextTranslations.js');
    // const link = useGetTranslatedText('links_TextTranslations.js');
    const activeLang = useSelector((state) => state.generalStore.selectedLanguage); 

    const [backgroundColor, setBackgroundColor] = useState('transparent');
    const [boxShadow, setBoxShadow] = useState('none');
    const [marginTop, setMarginTop] = useState(20);

    const containerRef = useRef(null);
    const ignoreRef = useRef(null);

    const dispatch = useDispatch();
     
    const toggleNavbar = e => {
        e.preventDefault()
        const navbarMenu = document.getElementById('navbarBasic');
        navbarMenu.classList.toggle('display-block')
    }

    useClickOutside(containerRef, () => {
        const navbarMenu = document.getElementById('navbarBasic');
        navbarMenu.classList.remove('display-block');
    },ignoreRef);
    
    useEffect(() => {
        const onScroll = () => {
            if (window.pageYOffset === 0) {
                setBackgroundColor('transparent');
                setBoxShadow('none');
            } else {
                setBackgroundColor('white');
                setBoxShadow('0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)');
            }
            if (window.pageYOffset < 100) {
                setMarginTop(20);
            } else {
                setMarginTop(0);
            }
        };
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, {passive: true});
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    const onChangeLang_Handler = (lang) =>{
        dispatch( storeGeneral_Actions.changeLang({lang:lang}));
    };

return(
    // <div className="hero-head" style={{backgroundColor: 'red'}}>
        <nav className="navbar is-fixed-top" style={{backgroundColor: backgroundColor, boxShadow: boxShadow}} role="navigation" aria-label="main navigation">
            <div className="container">
                <div className="navbar-brand" style={{marginTop: marginTop}}>
                    <Link to={'/'} className="navbar-item" id='main-logo'>
                        <h1 className="logo-text"><span>Ed</span>Neos</h1>
                    </Link>

                    <a
                        role="button"
                        className="navbar-burger"
                        aria-label="menu"
                        aria-expanded="false"
                        data-target="navbarBasicExample"
                        onClick={toggleNavbar}
                        ref={ignoreRef}
                    >
                        <span aria-hidden="true"/>
                        <span aria-hidden="true"/>
                        <span aria-hidden="true"/>
                    </a> 
                </div>

                <div id="navbarBasic" ref={containerRef}  className="navbar-menu"style={{marginTop: marginTop}}>
                    <div className="navbar-start">        
                        <a className="navbar-item" onClick={() =>scrollToSection('why_edneos')}>{text[4]}</a>
                        <a className="navbar-item" onClick={() => scrollToSection('product')}>{text[5]}</a>
                        <a className="navbar-item" onClick={() => scrollToSection('solution')}>{text[6]}</a>
                        {/* <a className="navbar-item" onClick={() =>scrollToSection('our_vision') }>{text[8]}</a> */}
                        {/* <a className="navbar-item" onClick={() =>scrollToSection('pricing') }>{text[7]}</a> */}
                        <a className="navbar-item" onClick={() =>scrollToSection('contact')}>{text[9]}</a>
                    </div>

                    <div className="navbar-end">
                        <a className="navbar-item">
                            <span style={activeLang === 'en' ? {color: '#4353ff'} : {color: '#4a4a4a'}}  onClick={() => onChangeLang_Handler('en')}>ENG </span>
                            <span> / </span>
                            <span style={activeLang === 'ro' ? {color: '#4353ff'} : {color: '#4a4a4a'}}  onClick={() => onChangeLang_Handler('ro')}> RO</span>
                        </a>
                        {/*<a className="navbar-item"*/}
                        {/*   href='http://app.edneos.com/auth/login'>{languages[languageIndex][5]}</a>*/}
                        <div className="navbar-item header-button">
                            <a className="button is-primary" onClick={() =>scrollToSection('contact')}>
                                <span>{text[10]}<SvgArrow1/></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    // </div>        
    );
};

export default Header;