import classes from './AppCard.module.css';
import ButtonEmptyWithBorder from '../../../../UI/Buttons/ButtonEmptyWithBorder';

function AppCard(props){

return (
    <div className={classes.container}>
        <div className={classes.header} style={{borderColor: props.color, backgroundColor: props.backColor}}>
            {/* <div className={classes.backImg}  style={{backgroundColor: props.color}}> */}
            {/* <div className={`${classes.centered} ${props.backImg}`}> */}                            
                <h3>{props.name}</h3>
                <p>{props.question}</p>
                <img src={props.picture} className={classes.pic} alt={"symbolic icon"}/>
                <ButtonEmptyWithBorder text={props.buttonText} backgroundColor = 'white'/>
                <h3 style={{textAlign: 'center', margin: '1.5rem 0 0.5rem 0'}}>{props.title}</h3>
                <div className={classes.contList}>
                    <ul style={{color: props.color}}>                        
                        {props.textList?.map((item) => {
                            return (<li key={Math.random()}><span>{item}</span></li>);
                        })}
                    </ul>
                </div>
        </div>
        <div className={classes.footer} style={{backgroundColor: props.backColor}}>

        </div>
        
    </div>
);
}


export default AppCard;    