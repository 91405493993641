
const generalTextTranslations= {
   ////////////// - Footer - //////////////
   0: {
         en:`© 2023 EdNeos. All rights reserved`,
         ro:`© 2023 EdNeos. Toate drepturile rezervate`
      },
   1: {
         en:`Terms and conditions`,
         ro:`Termeni si conditii`
      },
   2: {
         en:`Privacy policy`,
         ro:`Politica de confidențialitate`
      },     
   3: {
         en:`Developed by`,
         ro:`Creat de`
      },
   ////////////// - Header - //////////////
   // 'Why Edneos?',
   //      'Product',
   //      'Solution',
   //      'Pricing',
   //      'Our vision',
   //      'Contact Us',
   //      'Login',
   ///
   // 'Why Edneos?',
   // 'For Learners',
   // 'For Creator',
   // 'Pricing',
   // 'Our vision',
   // 'Contact Us',
   // 'Login',
   //
   // 'De ce EdNeos?',
   //      'Pentru cursanți',
   //      'Pentru Creator',
   //      'Prețuri',
   //      'Viziune',
   //      'Contactează-ne',
   //      'Autentificare',
   4: {
         en:`Why Edneos?`,
         ro:`De ce EdNeos?`
      },
   5: {
         en:`For Learners`, //en:`Product`,
         ro:`Pentru cursanți`  //ro:`Produs`
      },
 
   6: {
         en:`For Teachers`, //en:`Solution`,
         ro:`Pentru profesori` //ro:`Soluția`
      },
   7: {
         en:`Pricing`,
         ro:`Prețuri`
      },
   8: {
         en:`Our vision`,
         ro:`Viziune`
      },                              
   9: {
         en:`Contact Us`,
         ro:`Contactează-ne`
      },      
   10:{
         en:`Get started`,
         ro:`Să începem`
      },        
///////////////// Login - SignUp  ///////////////////
   11:{
         en:`Register new user`,
         ro:`Inregistrare utilizator nou`
      },
   12:{
         en:`E-mail`,
         ro:`E-mail`
      },
   13:{
         en:`Password`,
         ro:`Parola`
      },
   14:{
         en:`Confirm Password`,
         ro:`Confirmă Parola`
      },
   15:{
         en:`Create account`,
         ro:`Creare cont`
      },  
   16:{
         en:`Signing up...`,
         ro:`Inregistrare în progres ...`
      },    
   17:{
         en:`You have successfully registered!`,
         ro:`Ați fost înregistrat cu succes!`
      },                          
      
      
   };
   
   export default generalTextTranslations;
   

   
   