
const colors= {
    section: `rgb(245, 245, 245)` ,    
       
    };
    
    export default colors;
    
 
    
    