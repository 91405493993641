import classes from './Benefit.module.css';

function Benefit(props){
    // console.log(props.picture);
return (
    <div className={classes.benefit}>
        <div className={classes.benefitImg}>
            <div className={classes.backImg}  style={{backgroundColor: props.color}}>
                <img src={props.picture} className={classes.pic}/>
            </div>
        </div>
        <div className={classes.title}><h3>{props.title}</h3></div>
        <div className={classes.text}><p>{props.text}</p></div>
    </div>
);
}


export default Benefit;    