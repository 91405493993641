import classes from './Terms.module.css';
import Background from '../../COMPONENTS/UI/Background/Background';

import Footer from '../../COMPONENTS/UI/Footer/Footer';
import useGetTranslatedText from '../../HOOKS/useGetTranslatedText'; 


function Terms() {
    
    const pageText = useGetTranslatedText('termsPage_TextTranslations.js');


  return (
    <Background>        
        <div className={classes.container}>
            <h3><b>{pageText[0]}</b></h3>
            <p>&nbsp;</p>
            <p className={classes.indent}>{pageText[1]}</p>
            <p>&nbsp;</p>
            {/* 1 */}
            <p><b>{pageText[2]}</b></p>
            <p className={classes.indent}>{pageText[3]}</p>
            <p>&nbsp;</p>
            {/* 2 */}
            <p><b>{pageText[4]}</b></p>
            <p className={classes.indent}>{pageText[5]}</p>
            <p>&nbsp;</p>
            {/* 3 */}
            <p><b>{pageText[6]}</b></p>
            <p className={classes.indent}>{pageText[7]}</p>
            <p>&nbsp;</p>
            {/* 4 */}
            <p><b>{pageText[8]}</b></p>
            <p className={classes.indent}>{pageText[9]}</p>
            <p className={classes.indent}>{pageText[10]}</p>
            <p className={classes.indent}>{pageText[11]}</p>
            <p className={classes.indent}>{pageText[12]}</p>
            <p className={classes.indent}>{pageText[13]}</p>
            <p>&nbsp;</p>
            {/* 5 */}
            <p><b>{pageText[14]}</b></p>
            <p className={classes.indent}>{pageText[15]}</p>
            <p>&nbsp;</p>
            {/* 6 */}
            <p><b>{pageText[16]}</b></p>
            <p className={classes.indent}>{pageText[17]}</p>
            <p>&nbsp;</p>
            {/* 7 */}
            <p><b>{pageText[18]}</b></p>
            <p className={classes.indent}>{pageText[19]}</p>
            <p>&nbsp;</p>
            {/* 8 */}
            <p><b>{pageText[20]}</b></p>
            <p className={classes.indent}>{pageText[21]}</p>
            <p>&nbsp;</p>
            {/* 9 */}
            <p><b>{pageText[22]}</b></p>
            <p className={classes.indent}>{pageText[23]}</p>
            <p>&nbsp;</p>
            {/* 10 */}
            <p><b>{pageText[24]}</b></p>
            <p className={classes.indent}>{pageText[25]}</p>
            <p>&nbsp;</p>
            {/* 11 */}
            <p><b>{pageText[26]}</b></p>
            <p className={classes.indent}>
              <span>{pageText[27]}</span>
              <span><b>{pageText[28]}</b></span>
              <span>{pageText[29]}</span>
            </p>
            <p>&nbsp;</p>
            {/*  */}
            <p>
              <span>{pageText[30]}</span>
              <span><b>{pageText[31]}</b></span>
            </p>
            
            <p>&nbsp;</p>

            <p>{pageText[32]}</p>
            
            <p>&nbsp;</p>

         </div>
         <Footer/>
    </Background>
  );
}

export default Terms;
