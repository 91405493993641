import { useSelector } from 'react-redux';
import objOfObjectsToArray from '../UTILS/objOfObjectsToArray';

import homePageTextObj from '../TEXT/homePage_TextTranslations';
import termsPageTextObj from '../TEXT/termsPage_TextTranslations';
import privacyPageTextObj from '../TEXT/privacyPage_TextTranslations';

import generalTextsObj from '../TEXT/general_TextTranslations';
import errorsTextsObj from '../TEXT/errors_TextTranslations';
import linksObj from '../TEXT/links_TextTranslations';



function useGetTranslatedText(file){
    const activeLang = useSelector((state) => state.generalStore.selectedLanguage); 
    let translatedText;
    let allLangTextsArray;

      switch (file) {
        case 'homePage_TextTranslations.js':      
            allLangTextsArray = objOfObjectsToArray(homePageTextObj);
            break;
        case 'termsPage_TextTranslations.js':      
            allLangTextsArray = objOfObjectsToArray(termsPageTextObj);
            break;
        case 'privacyPage_TextTranslations.js':      
            allLangTextsArray = objOfObjectsToArray(privacyPageTextObj);
            break;                        
        case 'general_TextTranslations.js':
            allLangTextsArray = objOfObjectsToArray(generalTextsObj);            
            break;
        case 'errors_TextTranslations.js':
            allLangTextsArray = objOfObjectsToArray(errorsTextsObj);
            break;
        case 'links_TextTranslations.js':            
            allLangTextsArray = objOfObjectsToArray(linksObj);
        default:
            break;                             
    }

    translatedText = allLangTextsArray.map((textObj) => {return textObj[activeLang]});

    return translatedText;

}

export default useGetTranslatedText;