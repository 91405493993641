import classes from './Benefits.module.css';

import pic1 from "../../../../FILES/Images/pic1.png";
import pic2 from "../../../../FILES/Images/pic2.png";
import pic3 from "../../../../FILES/Images/pic3.png";
import pic4 from "../../../../FILES/Images/pic4.png";
import pic5 from "../../../../FILES/Images/pic5.png";
import Benefit from './Components/Benefit';
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText';
import SectionCard from '../../components/SectionCard';
// import colors from '../../../../UTILS/colors';

function Benefits(props){ 
    const text = useGetTranslatedText('homePage_TextTranslations.js');
return (
    <SectionCard flexDirection={`column`} id={props.id}>
            <div className={classes.containerText}>                  
                <h1>{text[55]}</h1>
                <h1>{text[109]}</h1>    
                <h1>{text[56]}</h1>    
            </div>
            <div className={classes.containerBenefits}>
                <Benefit picture={pic1} title={text[57]} text={text[62]} color={`rgb(236,237,255)`}/>
                <Benefit picture={pic2} title={text[58]} text={text[63]} color={`rgb(255,246,213)`}/>
                <Benefit picture={pic3} title={text[59]} text={text[64]} color={`rgb(255,234,233)`}/>
                <Benefit picture={pic4} title={text[60]} text={text[65]} color={`rgb(220,254,238)`}/>
                <Benefit picture={pic5} title={text[61]} text={text[66]} color={`rgb(252,235,255)`}/>
            
            </div>
    </SectionCard> 

);
}


export default Benefits;